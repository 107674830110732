'use client';

import React from 'react';
import { motion, useScroll, useTransform } from 'framer-motion';
import { Button } from '@repo/common/components';
import ScrollEffect from './ScrollEffect';
import KeepQueryLink from './KeepQueryLink';
import { useTranslation } from '../i18n/useTranslation';

export default function FinalOffer({ scrollTo = undefined }: { scrollTo?: string }) {
  const { t } = useTranslation();
  const { scrollYProgress } = useScroll();
  const y = useTransform(scrollYProgress, [0.75, 1], [200, -250]);
  return (
    <section className="lead-image relative mx-2 h-[89vh] md:h-[816px] rounded-[32px] bg-[#1F1F1F] flex justify-center items-center overflow-hidden">
      <ScrollEffect Element={motion.div} className="text-center text-white md:w-1/2 p-8 relative z-10">
        <h1 className="font-medium text-[1.9rem] leading-[2rem] md:leading-none md:text-5xl mb-6 text-center tracking-tighter md:tracking-[-0.15rem]">
          {t('landing.offer.title')}
        </h1>
        <p className="text-[15px] md:text-lg font-normal mb-8">
          {t('landing.offer.subtitle')}
        </p>
        <KeepQueryLink
          href="/intake"
          className="w-full md:w-auto"
          onClick={(e) => {
            if (scrollTo) {
              e.preventDefault();
              const element = document.getElementById(scrollTo);
              if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                const input = element.querySelector('input[type="text"]');
                if (input && input instanceof HTMLInputElement) {
                  input.focus();
                }
              }
            }
          }}
        >
          <Button type="primary" className="h-auto py-5 px-12 rounded-2xl text-base font-semibold mb-3 w-full md:w-auto">
            {t('landing.offer.cta')}
          </Button>
        </KeepQueryLink>
        <div className="text-[0.8rem] leading-[1.4rem] md:leading-4 md:text-sm flex items-center justify-center gap-1">
          <i className="material-icons text-base md:text-xl">schedule</i>
          {t('landing.offer.time')}
        </div>
      </ScrollEffect>
      <motion.div
        className="rect w-[467px] h-[245px] bg-[#303036] rotate-[-41deg] absolute left-0 bottom-0 translate-x-[15%] translate-y-[25%] z-0"
        style={{ y, x: '15%', rotate: '-41deg' }}
      />
    </section>
  );
}
